/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";
// import SignInBasic from "layouts/authentication/sign-in/basic";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setAuth,setRole } from "context";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
var roleAdded = 0;
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    role,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    auth,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const localJWT = localStorage.getItem("jwt");
  const sessionJWT = sessionStorage.getItem("jwt");
  

  if (localJWT && !sessionJWT) {
    sessionStorage.setItem("jwt", localJWT);
    sessionStorage.setItem("user", localStorage.getItem("user"));

  }

  if (sessionStorage.getItem("user") && !roleAdded) {
    roleAdded=1;

    let user = sessionStorage.getItem("user");
    user = JSON.parse(user);
    setRole(dispatch,user.role)
  }

  if (sessionStorage.getItem("jwt") && !auth) {
    setAuth(dispatch, true);
  }

  const isAuth = () => sessionStorage.getItem("jwt") != null;
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    console.log('role change');
    console.log(role);
  }, [role]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    // if (
    //   !isAuth() &&
    //   window.location.pathname !== "/" &&
    //   window.location.pathname !== "/signin" &&
    //   !window.location.pathname.includes("/status")
    // )
    if (
      !isAuth() && window.location.pathname !== "/signin"
    )
      return <Route path="*" element={<Navigate to="/signin" replace />} />;
    // if (isAuth() && window.location.pathname === "/")
    //   return <Route path="*" element={<Navigate to="/dashboard" replace />} />;


      console.log('in routes')
    return allRoutes.map((route) => {
      
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <>
        <Routes>{getRoutes(routes)}</Routes>
        {layout === "dashboard" && isAuth() && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="SmithBilt"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
      </>
    </ThemeProvider>
    </LocalizationProvider>
  );
}
