/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useNavigate } from "react-router-dom";

// Images
import bgImage from "assets/images/bg-slider.jpg";
import { getAuthHeaders } from "helpers";
import { CircularProgress, Grid } from "@mui/material";
import { useMaterialUIController, setAuth, setRole } from "context";
import axios from "../../../../axios";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
// import axios from "../../../axios";

function Basic() {
  const [, dispatch] = useMaterialUIController();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSubmit = async () => {
    try {
      if (!captchaToken) return console.log("invalid captcha");
      console.log("submit info");
      console.log(email);
      console.log(password);
      console.log(rememberMe);

      setLoading(true);
      const body = {
        email,
        password,
      };
      const result = await axios.post("/v1/users/auth", body, getAuthHeaders());
      console.log("login result");
      console.log(result);

      localStorage.clear();
      sessionStorage.clear();

      if (rememberMe) {
        localStorage.setItem("jwt", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.user));
      }
      sessionStorage.setItem("jwt", result.data.token);
      sessionStorage.setItem("user", JSON.stringify(result.data.user));
      setAuth(dispatch, true);
      setRole(dispatch, result.data.user.role);
      setLoading(false);
      console.log("navigate");
      if (result.data.user.role == "admin") navigate("/");
    } catch (error) {
      if (error?.response.data) alert(error?.response.data.message);
      else alert(error);
      console.log("error", error);
      setLoading(false);
    }
  };

  if (
    sessionStorage.getItem("jwt") !== null &&
    sessionStorage.getItem("user") !== null
  )
    navigate("/");

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="warning"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onBlur={(e) => setEmail(e.target.value)}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onBlur={(e) => setPassword(e.target.value)}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {loading ? (
                <Grid container justifyContent="center">
                  <CircularProgress color="warning" />
                </Grid>
              ) : (
                <MDButton
                  onClick={handleSubmit}
                  variant="gradient"
                  color="warning"
                  fullWidth
                >
                  sign in
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
        <GoogleReCaptcha
          onVerify={(token) => {
            console.log("captcha token");
            console.log(token);
            if (token && token.length > 1 && !captchaToken)
              setCaptchaToken(token);
          }}
        />
      </Card>
    </BasicLayout>
  );
}

export default Basic;
