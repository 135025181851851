/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
// @mui material components
import { Button, CircularProgress, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MUIDataTable from "mui-datatables";
import axios from "../../../axios";
import { getAuthHeaders } from "helpers";
import MDButton from "components/MDButton";
import { AttachFile } from "@mui/icons-material";

class Dashboard extends React.Component {
  componentDidMount() {
    this.getData();
  }

  state = {
    data: [],
    file: null,
    loading: true,
  };

  columns = [
    {
      name: "Description",
      label: "Description",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "QuantityOnHand",
      label: "QuantityOnHand",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "PhysicalCount",
      label: "PhysicalCount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "SKU",
      label: "SKU",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  options = {
    // filterType:/ "checkbox",
    selectableRows: "none", // <===== will turn off checkboxes in rows
  };

  getData = async () => {
    try {
      const result = await axios.get("/v1/product", getAuthHeaders());
      console.log("result", result);
      this.setState({ data: result.data, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  uploadFile = async () => {
    try {
      const result = await axios.get("/v1/product", getAuthHeaders());
      console.log("result", result);
      this.setState({ data: result.data, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  handleFileChange = (e) =>
    this.setState(
      {
        file: e.target.files[0],
        uploading: true,
      },
      async () => {
        try {
          var form = new FormData();
          form.append("file", this.state.file);

          const headers = getAuthHeaders();
          headers.headers["Content-Type"] = "multipart/form-data";

          const result = await axios.post(`/v1/product/`, form, headers);

          console.log("upload result");
          console.log(result);
          this.setState({ uploading: false });
          this.getData();
        } catch (error) {
          this.setState({ uploading: false });
          console.log(error);
        }
      }
    );

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox mt={1}>
            <Grid container>
              {this.state.uploading ? (
                <CircularProgress />
              ) : (
                <MDButton
                  variant="contained"
                  color="warning"
                  startIcon={<AttachFile />}
                  component="label"
                >
                  Upload CSV
                  <input
                    onChange={this.handleFileChange}
                    hidden
                    accept=".csv"
                    type="file"
                  />
                </MDButton>
              )}
            </Grid>
            <Grid container sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <MDBox mb={1.5}>
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    <MUIDataTable
                      title={"Inventory"}
                      data={this.state.data}
                      columns={this.columns}
                      options={this.options}
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default Dashboard;
